import * as React from 'react'
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews'

const NotFoundPage = () => (
  <div className="min-vh-100 d-flex flex-column">
    <div className="flex-fill d-flex flex-column justify-content-center py-5">
	  	<div className="container text-center">
	  		<h1 className="fw-bold text-404">404</h1>
	  		<h2 className="fw-bold">Page not found</h2>
	  		<p className="mt-4">Sorry we can't find the page you're looking for.</p>
	  		<a href="/" className="mt-4 btn btn-primary text-uppercase">Back to home</a>
	  	</div>
	  </div>
  </div>
)

export default withPrismicUnpublishedPreview(NotFoundPage)
